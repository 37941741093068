import { createSlice } from '@reduxjs/toolkit'

export const employeeAttendenceSlice = createSlice({
    name: "employeeAttendence",
    initialState: {
        indivisualAttendence: []
    },
    reducers: {
        indivisualEmpAttendenceGet: (state, action) => {
            state.indivisualAttendence = action?.payload
        }
    }
})

export const { indivisualEmpAttendenceGet } = employeeAttendenceSlice.actions


export default employeeAttendenceSlice.reducer