import { createSlice } from "@reduxjs/toolkit"

const getItemIndex = (state, idToFind) => {
  const ids = state.map(item => item.product_menu_id)
  return ids.indexOf(idToFind)
}

const cartMenuSlice = createSlice({
  name: 'cartMenu',
  initialState: {
    cartItem: [],
    cartupdate: []
  },

  reducers: {
    addToCart: (state, action) => {

      // state?.cartItem?.push({ ...action.payload})
      const itemInCart = state?.cartItem?.find((item) => item.product_menu_id === action.payload.product_menu_id)
      if (itemInCart) {
        itemInCart.quantity++
      } else {
        state?.cartItem?.push({ ...action.payload, quantity: 1 })
        // state.push = [{ ...action.payload }]
  

      }
    },
    updatecard: (state, action) => {
      // console.log('up', action?.payload)
      state.cartItem = action?.payload
    },

    incrementQuantity: (state, action) => {
      // console.log('ct', action?.payload)
      const itemInCart = state?.cartItem?.find((item) => item.product_menu_id === action?.payload.product_menu_id)

      if (itemInCart) {
        itemInCart.quantity++
      }
    },
    decrementQuantity: (state, action) => {

      const itemInCart = state?.cartItem?.find((item) => item?.product_menu_id === action?.payload?.product_menu_id)
      if (itemInCart) {
        if (itemInCart.quantity >= 1) {
          itemInCart.quantity--
        } else if (itemInCart.quantity === 0) {

        } else {
          state.cartItem = state?.cartItem.filter(item => item?.product_menu_id !== product_menu_id)
        }
      }
    },

    cleaState: (state, action) => {
      state.cartItem = []
    },

    removeFromCart: (state, action) => {
      // log(action)
      const index = state?.cartItem?.findIndex(x => x.product_menu_id === action.payload?.product_menu_id)
      state?.cartItem?.splice(index, 1)
    }

  }
})

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  cleaState,
  updatecard
} = cartMenuSlice.actions
export default cartMenuSlice.reducer