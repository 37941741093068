import { createSlice } from '@reduxjs/toolkit'


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboard: {
            data: []
        },
        dashboardemp: {
            data: []
        },
        dashboardList: {
            data: []
        },
        dashboardListcategory: {
            data: []
        }
    },
    reducers: {
        dashboardLoad: (state, action) => {
            state.dashboard = action?.payload
        },
        dashboardListLoad: (state, action) => {
            state.dashboardList = action?.payload
        },
        dashboardLoademp: (state, action) => {
            state.dashboardemp = action?.payload
        },
        dashboardcategoryLoad: (state, action) => {
            state.dashboardListcategory = action?.payload
        }
        // dashboardView: (state, action) => {
        //     const index = state.dashboard.data.findIndex(x => x.id === action.payload.id)
        //     state.dashboard.data[index] = action.payload
        // },
        // dashboardUpdate: (state, action) => {
        //     const index = state.dashboard.data.findIndex(x => x.id === action.payload.id)
        //     state.dashboard.data[index] = action.payload
        // },
        // dashboardSave: (state, action) => {
        //     state.dashboard.data = [
        //         ...action?.payload,
        //         ...state?.dashboard?.data
        //     ]
        // },
        // dashboardDelete: (state, action) => {
        //     // log(action)
        //     const index = state.dashboard.data.findIndex(x => x.id === action.payload)
        //     state.dashboard.data.splice(index, 1)
        // }
    }
})

export const { dashboardLoad, dashboardLoademp, dashboardListLoad,  dashboardcategoryLoad } = dashboardSlice.actions

export default dashboardSlice.reducer