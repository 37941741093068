// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const orderSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: {
            data: []
        }
    },
    reducers: {
        orderView: (state, action) => {
            const index = state.orders.data.findIndex(x => x.id === action.payload.id)
            state.orders.data[index] = action.payload
        },
        orderLoad: (state, action) => {
            state.orders = action?.payload
        },
        orderBillLoad: (state, action) => {
            state.orders = action?.payload
        },
        orderUpdate: (state, action) => {
            const index = state.orders.data.findIndex(x => x.id === action.payload.id)
            state.orders.data[index] = action.payload
        },
        orderSave: (state, action) => {
            state.orders.data = [
                ...action?.payload,
                ...state?.orders?.data
            ]
        },

        orderDelete: (state, action) => {
            const index = state.customers.data.findIndex(x => x.id === action.payload)
            state.customers.data.splice(index, 1)
        }

    }
})

export const { orderDelete, orderLoad, orderSave, orderUpdate, orderView, orderBillLoad } = orderSlice.actions


export default orderSlice.reducer

