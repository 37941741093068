import { createSlice } from '@reduxjs/toolkit'

export const employeesalarySlice = createSlice({
    name: 'empsalary',
    initialState: {
        empsalary: {
            data: []
        }
    },
    reducers: {
        empsalaryView: (state, action) => {
            const index = state.empsalary.data.findIndex(x => x.id === action.payload.id)
            state.empsalary.data[index] = action.payload
        },
        empsalaryLoad: (state, action) => {
            state.empsalary = action?.payload
        },
        empsalaryUpdate: (state, action) => {
            const index = state.empsalary.data.findIndex(x => x.id === action.payload.id)
            state.empsalary.data[index] = action.payload
        },
        empsalarySave: (state, action) => {
            state.empsalary.data = [
                ...action?.payload,
                ...state?.empsalary?.data
            ]
        },

        empsalaryDelete: (state, action) => {
            const index = state.empsalary.data.findIndex(x => x.id === action.payload)
            state.empsalary.data.splice(index, 1)
        }

    }
})

export const { empsalaryDelete, empsalaryLoad, empsalarySave, empsalaryUpdate, empsalaryView} = employeesalarySlice.actions


export default employeesalarySlice.reducer