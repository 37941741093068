// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import product from "../redux/reducers/product"
import unit from "../redux/reducers/unit"
import recipes from "../redux/reducers/recipes"
import customer from "../redux/reducers/customer"
import stockmanage from "../redux/reducers/stockmanage"
import order from '../redux/reducers/order'
import category from "../redux/reducers/category"
import Menuproduct from "../redux/reducers/Menuproduct"
import expense from '../redux/reducers/expense'
import store from '../redux/reducers/cafe/index'
import cartMenuSlice from '../redux/reducers/carts'
import employees from "../redux/reducers/employees"
import employeeAttendence from "../redux/reducers/employeeAttendence"
import fileUpload from "../redux/reducers/fileUpload"
import customeraccount from './reducers/customeraccount'
import subcategory from './reducers/category/subcategory'
import dashboard from './reducers/dashboard'
import menuSliceList from './reducers/Menuproduct/product-Menu-list'
import employeesalary from "../redux/reducers/employeesalary"
import pack from './reducers/pack'

const rootReducer = {
    auth,
    todo,
    chat,
    email,
    users,
    kanban,
    navbar,
    layout,
    invoice,
    calendar,
    ecommerce,
    dataTables,
    permissions,
    employeesalary,
    product,
    unit,
    customer,
    category,
    Menuproduct,
    expense,
    pack,
    employees,
    recipes,
    stockmanage,
    order,
    employeeAttendence,
    customeraccount,
    subcategory,
    dashboard,
    menuSliceList,
    store,
    cartMenuSlice
}

export default rootReducer
