import { createSlice } from '@reduxjs/toolkit'

export const employeeSlice = createSlice({
    name: 'employees',
    initialState: {
        employeelist: null,
        employeeAttendence: null
    },
    reducers: {
        employeeAdd: (state, action) => {
            state.employeelist = [
                ...action?.employeelist,
                ...state?.employeelist
            ]
            // console.log(".........", action.payload)
            // state.employeelist.push(action?.payload)
        },
        employeeGet: (state, action) => {
            state.employeelist = action?.payload
        },
        employeeUpdate: (state, action) => {
            const index = state.employeelist.findIndex(x => x.id === action.payload.id)
            state.employeelist[index] = action.payload.data
            state.employeelist[index].id = action.payload.id
        },
        // employeeDelete: (state, action) => {
        //     const index = state.employeelist.findIndex(x => x.id === action.payload)
        //     if (index > -1) {
        //         state.employeelist.splice(index, 1)
        //     }
        // },
        employeeSalaryLoad: (state, action) => {
            state.expense = action?.payload
        },
         employeeDelete: (state, action) => {
            // log(action)
            const index = state.employeelist.data.findIndex(x => x.id === action.payload)
            state.employeelist.data.splice(index, 1)
        },
        employeeAttendence: (state, action) => {
            // console.log(action.payload)
            state.employeeAttendence.push(action.payload)
        },
        employeeAttendenceGet: (state, action) => {
            state.employeeAttendence = action.payload
        },
        employeeAttendenceUpdate: (state, action) => {
            // console.log("action.payload.....", action.payload)
            const index = state.employeeAttendence.findIndex(x => x.id === action.payload.id)
            state.employeeAttendence[index] = action.payload
        }

    }
})

export const { employeeAdd, employeeGet, employeeUpdate, employeeDelete, employeeSalaryLoad, employeeAttendenceUpdate, employeeAttendence, employeeAttendenceGet } = employeeSlice.actions


export default employeeSlice.reducer
