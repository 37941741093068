import { createSlice } from '@reduxjs/toolkit'


export const subcategorySlice = createSlice({
  name: 'subcategory',
  initialState: {
    subcategory: {
      data: []
    }
  },
  reducers: {
    subcategoryLoad: (state, action) => {
      state.subcategory = action?.payload
    },
    subcategoryView: (state, action) => {
      const index = state.subcategory.data.findIndex(x => x.id === action.payload.id)
      state.subcategory.data[index] = action.payload
    },
    subcategoryUpdate: (state, action) => {
      const index = state.subcategory.data.findIndex(x => x.id === action.payload.id)
      state.subcategory.data[index] = action.payload
    },
    subcategorySave: (state, action) => {
      state.subcategory.data = [
        ...action?.payload,
        ...state?.subcategory?.data
      ]
    },
    subcategoryDelete: (state, action) => {
      log(action)
      const index = state.subcategory.data.findIndex(x => x.id === action.payload)
      state.subcategory.data.splice(index, 1)
    }
  }
})
// fdkjodjd
export const { subcategoryDelete, subcategoryLoad, subcategorySave, subcategoryUpdate, subcategoryView } = subcategorySlice.actions

export default subcategorySlice.reducer
