import { createSlice } from '@reduxjs/toolkit'

export const cafeSlice = createSlice({
    name: 'cafe',
    initialState: {
        cafe: {
            data: []
        }
    },
    reducers: {
        cafeLoad: (state, action) => {
            state.cafe = action?.payload
        },
        cafeView: (state, action) => {
            const index = state.cafe.data.findIndex(x => x.id === action.payload.id)
            state.cafe.data[index] = action.payload
        },
        cafeUpdate: (state, action) => {
            const index = state.cafe.data.findIndex(x => x.id === action.payload.id)
            state.cafe.data[index] = action.payload
        },
        cafeSave: (state, action) => {
            
            state.cafe.data = [
                ...action?.payload,
                ...state?.cafe?.payload
            ]
        },
        cafeDelete: (state, action) => {

            const index = state.cafe.data.findIndex(x => x.id === action.payload)
            state.cafe.data.splice(index, 1)
        }
    }
})

export const { cafeDelete, cafeLoad, cafeSave, cafeUpdate, cafeView } = cafeSlice.actions

export default cafeSlice.reducer