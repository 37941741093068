import { createSlice } from '@reduxjs/toolkit'
export const menuSliceList = createSlice({
    name: 'menulist',
    initialState: {
        menulist: {
            data: []
        }
    },
    reducers: {
        munulistLoad: (state, action) => {
            state.menulist = action?.payload
        },
        munulistView: (state, action) => {
            const index = state.list.data.findIndex(x => x.id === action.payload.id)
            state.menulist.data[index] = action.payload
        }

    }
})

export const { munulistLoad, munulistView } = menuSliceList.actions
export default menuSliceList.reducer