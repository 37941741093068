import { createSlice } from '@reduxjs/toolkit'
export const fileSlice = createSlice({
    name: 'fileupload',
    initialState: {
        file: {
            data: []
        }
    },
    reducers: {
        uploadFile: (state, action) => {
            state.file.data = [
                ...action?.payload,
                ...state?.file?.data
            ]
        },
        uploadFileList: (state, action) => {
            state.file = action?.payload
        },
        deleteFile: (state, action) => {
            const index = state.file.data.findIndex(x => x.id === action.payload)
            if (index >= 0) {
                state.file.data.splice(index, 1)
            }
        }

    }
})

export const { uploadFile, uploadFileList, deleteFile } = fileSlice.actions

export default fileSlice.reducer