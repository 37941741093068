// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const stockmanageSlice = createSlice({
    name: 'stocks',
    initialState: {
        stocks: {
            data: []
        }
    },
    reducers: {
        stockView: (state, action) => {
            const index = state.stocks.data.findIndex(x => x.id === action.payload.id)
            state.stocks.data[index] = action.payload
        },
        stockLoad: (state, action) => {
            state.stocks = action?.payload
        },
        stockUpdate: (state, action) => {
            const index = state.stocks.data.findIndex(x => x.id === action.payload.id)
            state.stocks.data[index] = action.payload
        },
        stockSave: (state, action) => {
            state.stocks.data = [
                ...action?.payload,
                ...state?.stocks?.data
            ]
        },

        stockDelete: (state, action) => {
            const index = state.stocks.data.findIndex(x => x.id === action.payload)
            state.stocks.data.splice(index, 1)
        }

    }
})

export const { stockDelete, stockLoad, stockSave, stockUpdate, stockView } = stockmanageSlice.actions


export default stockmanageSlice.reducer

