import { createSlice } from '@reduxjs/toolkit'

export const packSlice = createSlice({
    name: 'pack',
    initialState: {
        pack: {
            data: []
        }
    },
    reducers: {
        packLoad: (state, action) => {
            state.pack = action?.payload
        },
        packView: (state, action) => {
            const index = state.pack.data.findIndex(x => x.id === action.payload.id)
            state.pack.data[index] = action.payload
        },
        packUpdate: (state, action) => {
            const index = state.pack.data.findIndex(x => x.id === action.payload.id)
            state.pack.data[index] = action.payload
        },
        packSave: (state, action) => {
            state.pack.data = [
                ...action?.payload,
                ...state?.pack?.data
            ]
        },
        packDelete: (state, action) => {

            const index = state.pack.data.findIndex(x => x.id === action.payload)
            state.pack.data.splice(index, 1)
        }
    }
})

export const { packDelete, packLoad, packSave, packUpdate, packView } = packSlice.actions

export default packSlice.reducer
