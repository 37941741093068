// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const customeraccSlice = createSlice({
    name: 'customeraccs',
    initialState: {
        customeraccs: {
            data: []
        }
    },
    reducers: {
        customeraccView: (state, action) => {
            const index = state.customeraccs.data.findIndex(x => x.id === action.payload.id)
            state.customeraccs.data[index] = action.payload
        },
        customeraccLoad: (state, action) => {
            state.customeraccs = action?.payload
        },
        customeraccUpdate: (state, action) => {
            const index = state.customeraccs.data.findIndex(x => x.id === action.payload.id)
            state.customeraccs.data[index] = action.payload
        },
        customeraccSave: (state, action) => {
            state.customeraccs.data = [
                ...action?.payload,
                ...state?.customeraccs?.data
            ]
        },

        customeraccDelete: (state, action) => {
            const index = state.customeraccs.data.findIndex(x => x.id === action.payload)
            state.customeraccs.data.splice(index, 1)
        }

    }
})

export const { customeraccDelete, customeraccLoad, customeraccSave, customeraccUpdate, customeraccView } = customeraccSlice.actions


export default customeraccSlice.reducer

